import React, { Component } from "react";
import { Input, Form, Button } from "antd";
// import { createForm } from "rc-form";
// import { withStyles, Button, Typography } from "@material-ui/core";

const setUserDetailsInLocalStorage = ({ userName, password }) => {
  try {
    localStorage.setItem("user", JSON.stringify({ userName, password }));
  } catch (err) {
    console.log(err);
  }
};

const isAuthenticated = (userName, password) => {
  if (userName === "himalayan" && password === "himalayanweekenders@123") {
    return true;
  }

  if (userName === "weekenders" && password === "himalayanweekenders@123") {
    return true;
  }

  return false;
};

class Login extends Component {
  onSubmit = () => {
    this.props.form.validateFields((error, values) => {
      if (!error) {
        if (isAuthenticated(values.userName, values.password)) {
          setUserDetailsInLocalStorage(values);
          this.props.history.push("/list");
        }
      }
      console.log(error);
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form
          style={{ maxWidth: 400, margin: "auto", paddingTop: "30vh" }}
          onSubmit={this.onSubmit}
        >
          <h2 style={{ textAlign: "center", marginBottom: 50 }}>Login</h2>
          <Form.Item>
            {getFieldDecorator("userName", {
              rules: [
                { required: true, message: "Please enter a valid user name" }
              ]
            })(<Input size="large" placeholder="Please enter your email ID" />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please enter a valid user name" }
              ]
            })(
              <Input
                type="password"
                size="large"
                placeholder="Please enter your password"
              />
            )}
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              size="large"
              onClick={this.onSubmit}
              style={{
                textTransform: "uppercase",
                marginTop: 20,
                width: 150
              }}
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const WrappedLogin = Form.create()(Login);

export default WrappedLogin;
