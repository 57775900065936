import React, { Component } from "react";
import { initializeFirebase } from "./modules/common/utils";
import { Switch, Route } from "react-router";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

// import DetailsView from "./modules/productDetails/components/DetailsView";
import ListView from "./modules/productList/components/ListView";
import AddView from "./modules/productDetails/components/AddView";
import UpdateView from "./modules/productDetails/components/UpdateView";
import Login from "./Login";
import HomepageItemsEdit from "./modules/homepageItems/components/HomepageItemsEdit";

initializeFirebase();

const isAuthenticated = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
  } catch (err) {
    return false;
  }

  if (user) {
    return true;
  }
  return false;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={() => <Redirect to="/list" />} />
          <Route path="/login" component={Login} />
          <PrivateRoute path="/list" component={ListView} />
          <PrivateRoute path="/add" component={AddView} />
          <PrivateRoute path="/update/:slug" component={UpdateView} />
          <PrivateRoute path="/homepage-edit" component={HomepageItemsEdit} />
        </Switch>
      </Router>
    );
  }
}

export default App;
