import React, { Component, Fragment } from "react";
import { getHomepageItems, saveHomepageItems } from "../../common/utils";
import { Form, Input, Checkbox, Button, message, Spin, Icon } from "antd";
import { css } from "emotion";
import Layout from "../../productDetails/components/Layout";

class HomepageItemsEdit extends Component {
  state = {
    products: null,
    isLoading: false
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    getHomepageItems()
      .then(products => this.setState({ products, isLoading: false }))
      .catch(err => {
        this.setState({ isLoading: false });
        message.error("Something went wrong, Please refresh again");
      });
  }
  save = e => {
    e.preventDefault();
    this.props.form.validateFields((error, fields) => {
      if (error) {
        return;
      }

      console.log("SAVING DATA", fields);
      this.setState({ isLoading: true });
      saveHomepageItems(fields.products)
        .then(r => {
          this.setState({ isLoading: false });
          window.location.reload();
        })
        .catch(err => {
          this.setState({ isLoading: false });
          message.error("Something went wrong, Please refresh again");
        });
    });
  };
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { products, isLoading } = this.state;
    console.log(products);

    if (isLoading) {
      return (
        <div
          className={css`
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.02);
          `}
        >
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 48 }} spin />}
          />
        </div>
      );
    }

    return (
      <Layout>
        <div
          className={css`
            margin: auto;
            width: 70vw;
            max-width: 600px;
            margin-bottom: 200px;
          `}
        >
          <h1
            className={css`
              text-align: center;
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 20px;
            `}
          >
            Homepage Carousel
          </h1>
          <Form onSubmit={this.save}>
            {products &&
              products.map((product, index) => (
                <Fragment>
                  <Form.Item>
                    {getFieldDecorator(`products[${index}].id`, {
                      initialValue: product.id
                    })(
                      <Input
                        style={{ display: "none" }}
                        placeholder="ID"
                        disabled
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator(`products[${index}].name`, {
                      initialValue: product.data.name,
                      rules: [
                        {
                          required: true,
                          message: "This is required"
                        }
                      ]
                    })(<Input placeholder="Name" />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator(`products[${index}].price`, {
                      initialValue: product.data.price,
                      rules: [
                        {
                          required: true,
                          message: "This is required"
                        }
                      ]
                    })(<Input placeholder="price" />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator(`products[${index}].slug`, {
                      initialValue: product.data.slug
                    })(<Input placeholder="slug" />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator(`products[${index}].image`, {
                      initialValue: product.data.image,
                      rules: [
                        {
                          required: true,
                          message: "This is required"
                        }
                      ]
                    })(<Input placeholder="Image URL" />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator(`products[${index}].isNew`, {
                      initialValue: !!product.data.isNew
                    })(
                      <Checkbox
                        checked={getFieldValue(`products[${index}].isNew`)}
                      >
                        Show Register Button
                      </Checkbox>
                    )}
                  </Form.Item>
                </Fragment>
              ))}
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              onClick={this.save}
              style={{ width: 200 }}
            >
              Save
            </Button>
          </Form>
        </div>
      </Layout>
    );
  }
}

export default Form.create()(HomepageItemsEdit);
