import firebase from "firebase";

export const getDB = () => {
  return firebase.firestore();
};

export const initializeFirebase = () => {
  // const config = {
  //   apiKey: "AIzaSyC5ug_jyqdo8CkP_z8GqLdU_lQM6kM6kzU",
  //   authDomain: "hw-stag.firebaseapp.com",
  //   databaseURL: "https://hw-stag.firebaseio.com",
  //   projectId: "hw-stag",
  //   storageBucket: "hw-stag.appspot.com",
  //   messagingSenderId: "241111925552"
  // };
  const config = {
    apiKey: "AIzaSyBcyZzbwob16FnY679TK5xXmQwGUAUmt9s",
    authDomain: "hw-prod-76c19.firebaseapp.com",
    databaseURL: "https://hw-prod-76c19.firebaseio.com",
    projectId: "hw-prod-76c19",
    storageBucket: "",
    messagingSenderId: "743227911438"
  };
  firebase.initializeApp(config);
};

export const addToDB = data => {
  const db = getDB();
  const collection = process.env.REACT_APP_PRODUCT_COLLECTION;

  return db
    .collection(collection)
    .where("slug", "==", data.slug)
    .get()
    .then(snapshot => {
      if (!snapshot.docs.length) {
        return db
          .collection(collection)
          .add(JSON.parse(JSON.stringify(data)))
          .then(docRef => {
            console.log("done", docRef.id);
            return docRef.id;
          });
      } else {
        return -1;
      }
    });
};

export const getProductDetails = slug => {
  const db = getDB();
  const collection = process.env.REACT_APP_PRODUCT_COLLECTION;
  return db
    .collection(collection)
    .where("slug", "==", slug)
    .get()
    .then(snapshot => {
      return {
        id: snapshot.docs[0].id,
        data: snapshot.docs[0].data()
      };
    });
};

export const updateProductDetails = (data, id) => {
  const db = getDB();
  const collection = process.env.REACT_APP_PRODUCT_COLLECTION;
  const ref = db.collection(collection).doc(id);
  const sanitizedData = JSON.parse(JSON.stringify(data));
  return ref.set({
    ...sanitizedData
  });
};

export const getHomepageItems = () => {
  const db = getDB();
  const collection = "hompageCarousel";
  return db
    .collection(collection)
    .get()
    .then(snapshot => snapshot.docs.map(d => ({ id: d.id, data: d.data() })));
};

export const saveHomepageItems = data => {
  const db = getDB();
  const collection = "hompageCarousel";
  const promises = data.map(datum => {
    const { id, ...rest } = datum;
    const ref = db.collection(collection).doc(id);
    const sanitizedData = JSON.parse(JSON.stringify(rest));
    return ref.set({
      ...sanitizedData
    });
  });
  return Promise.all(promises);
};
