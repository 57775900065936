import React, { Component } from "react";
import { getDB } from "../../common/utils";
import { Icon, List, Row, Col } from "antd";
import styled, { css } from "react-emotion";
import Layout from "../../productDetails/components/Layout";

const StyledProductItem = styled("div")`
  position: relative;
  cursor: pointer;
  width: 100%;

  h2 {
    font-size: 20px;
    font-weight: 400;
    margin: 8px 0;
    margin-top: 0;
  }

  > div {
    font-size: 14px;

    i {
      vertical-align: middle;
    }
  }
`;

class ListView extends Component {
  state = {
    products: null,
    isLoading: false,
    searchValue: "",
    currentProductType: "All"
  };
  setLoading = isLoading => {
    this.setState({
      isLoading
    });
  };
  onSearch = e => {
    const { value } = e.target;
    this.setState({
      searchValue: value.toLowerCase()
    });
  };
  componentDidMount() {
    const collectionName = process.env.REACT_APP_PRODUCT_COLLECTION;
    const db = getDB();
    this.setLoading(true);
    db.collection(collectionName)
      .get()
      .then(snapshots => {
        const products = snapshots.docs.map(d => d.data());
        this.setLoading(false);
        this.setState({
          products: products
            .map(p => ({ ...p, ...p.details }))
            .sort((a, b) => {
              const aName = a.name.toUpperCase();
              const bName = b.name.toUpperCase();
              if (aName < bName) {
                return -1;
              }
              if (aName > bName) {
                return 1;
              }
              return 0;
            })
        });
      })
      .catch(err => this.setLoading(false));
  }
  renderProductType = type => {
    if (!type) {
      return "";
    }

    return type
      .split("_")
      .map(s => s[0] + s.substr(1))
      .join(" ");
  };
  getAllProductTypes = () => {
    const { products } = this.state;
    if (products) {
      return ["All", ...Array.from(new Set(products.map(p => p.type)))];
    }
    return null;
  };
  setCurrentProductType = type => {
    this.setState({ currentProductType: type });
  };
  handleItemClick = ({ slug }) => this.props.history.push(`/update/${slug}`);

  getFilteredProductList = () => {
    const { searchValue, currentProductType, products } = this.state;

    let filteredProducts = products;

    if (searchValue) {
      filteredProducts = filteredProducts.filter(s =>
        s.name.toLowerCase().includes(searchValue)
      );
    }

    if (currentProductType && currentProductType !== "All") {
      filteredProducts = filteredProducts.filter(
        s => s.type === currentProductType
      );
    }

    return filteredProducts;
  };

  render() {
    const { isLoading, products, searchValue, currentProductType } = this.state;

    if (isLoading) {
      return (
        <div>
          <Icon type="loading" />
        </div>
      );
    }

    return (
      <Layout
        showSearchBar={true}
        onSearch={this.onSearch}
        searchValue={searchValue}
      >
        <div style={{ marginBottom: 200 }}>
          <h1
            className={css`
              text-align: center;
              font-size: 24px;
              font-weight: 500;
            `}
          >
            Products
          </h1>
          {this.getAllProductTypes() && (
            <Row
              // type="flex"
              className={css`
                width: 70vw;
                max-width: 600px;
                margin: auto;
                margin-bottom: 12px;
                font-size: 16px;
                border: 1px solid #e8e8e8;
                border-radius: 4px;
                position: fixed;
                width: 200px;
                left: calc(50% - 510px);
              `}
            >
              {this.getAllProductTypes().map(p => (
                <Col
                  onClick={() => this.setCurrentProductType(p)}
                  // xs={4}
                  className={css`
                    border-bottom: 1px solid #e8e8e8;
                    cursor: pointer;
                    padding: 5px 10px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                      background: #eee;
                    }
                  `}
                  style={
                    currentProductType === p ? { background: "yellow" } : {}
                  }
                >
                  {this.renderProductType(p)}
                </Col>
              ))}
            </Row>
          )}

          {products && (
            <List
              bordered
              className={css`
                width: 70vw;
                max-width: 600px;
                margin: auto;
              `}
              itemLayout="horizontal"
              dataSource={this.getFilteredProductList()}
              renderItem={item => (
                <List.Item
                  onClick={() => this.handleItemClick(item)}
                  className={css`
                    background: ${item.hide
                      ? "rgba(204, 204, 204, 0.4)"
                      : "transparent"};
                    opacity: ${item.hide ? "0.5" : "1"};
                    &:hover {
                      background: #eee;
                    }
                  `}
                >
                  <StyledProductItem>
                    <h2>{item.name}</h2>
                    <div>
                      <span>₹ {item.price}</span>
                      <span
                        className={css`
                          position: absolute;
                          top: 10px;
                          right: 0;
                          border-radius: 4px;
                          background-color: yellow;
                          padding: 2px 4px;
                          font-size: 12px;
                        `}
                      >
                        {this.renderProductType(item.type)}
                      </span>
                      {item.duration ? (
                        <span
                          className={css`
                            display: inline-block;
                            margin-left: 12px;
                          `}
                        >
                          <Icon type="calendar" /> {item.duration}
                        </span>
                      ) : null}
                    </div>
                  </StyledProductItem>
                </List.Item>
              )}
            />
          )}
        </div>
      </Layout>
    );
  }
}

export default props => (
  // <Layout>
  <ListView {...props} />
  // </Layout>
);
