import React, { Component, Fragment } from "react";
import { Form, Input, Select, Button, Row, Col, Icon, Switch } from "antd";
import { css } from "emotion";
import lodashGet from "lodash/get";

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

class DetailsView extends Component {
  state = {
    imagesCounter: [1],
    itinerary: lodashGet(this.props, "data.details.itinerary", []) // this.props.data && (this.props.data.details.itinerary || [])
  };
  addItineraryItem = () => {
    this.setState(state => ({
      itinerary: state.itinerary.concat([{ content: [""] }])
    }));
  };
  removeItineraryItem = index => {
    this.setState(state => ({
      itinerary: [
        ...state.itinerary.slice(0, index),
        ...state.itinerary.slice(index + 1, Infinity)
      ]
    }));
  };
  addImage = () => {
    this.setState(state => ({
      imagesCounter: state.imagesCounter.concat([1])
    }));
  };
  removeImage = index => {
    this.setState(state => ({
      imagesCounter: [
        ...state.imagesCounter.slice(0, index),
        ...state.imagesCounter.slice(index + 1, Infinity)
      ]
    }));
  };
  handleSubmit = e => {
    e.preventDefault();
    const { data = {} } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const { itineraryFromUser = [], ...restValues } = values;
        const dataToBeSaved = {
          ...restValues,
          details: {
            ...restValues.details,
            itinerary: itineraryFromUser
              .map(item => ({
                content: item.split("\n\n").filter(item => !!item)
              }))
              .filter(item => item.content.length)
          },
          index: data.index || 0,
          slug:
            data.slug ||
            values.name
              .replace("-", "")
              .split(" ")
              .map(t => t.toLowerCase())
              .join("-")
        };
        console.log("dataToBeSaved", dataToBeSaved);
        // return;
        this.props.onSubmit(dataToBeSaved);
      }
    });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      this.setState({
        imagesCounter: data.details.images,
        itinerary: data.details.itinerary || []
      });
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { imagesCounter, itinerary } = this.state;
    let { data } = this.props;
    console.log("actual data", data);

    if (!data) {
      data = {
        details: {
          images: []
        }
      };
    }

    return (
      <Form
        onSubmit={this.handleSubmit}
        className={css`
          width: 70vw;
          max-width: 600px;
          margin: auto;
        `}
      >
        {/* Name of the product */}
        <FormItem label="Name">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Please provide a name" }],
            initialValue: data.name
          })(<Input />)}
        </FormItem>

        <Row gutter={16}>
          <Col span={12}>
            {/* Type of the product */}
            <FormItem label="Type">
              {getFieldDecorator("type", {
                rules: [
                  { required: true, message: "Please select type of product" }
                ],
                initialValue: data.type
              })(
                <Select>
                  <Option value="ADVENTURE_TRIP">Adventure Sports</Option>
                  <Option value="TRIP">Trip</Option>
                  <Option value="TREK">Trek</Option>
                  <Option value="WEEKEND_GETAWAYS">Weekend Getaways</Option>
                </Select>
              )}
            </FormItem>
          </Col>

          <Col span={12}>
            {/* Difficulty of the product */}
            <FormItem label="Difficulty">
              {getFieldDecorator("details.difficulty", {
                // rules: [
                //   { required: true, message: "Please select difficulty" }
                // ],
                initialValue: data.details.difficulty
              })(
                <Select>
                  <Option value="Hard">Hard</Option>
                  <Option value="Medium">Medium</Option>
                  <Option value="Easy">Easy</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        {/* Map Location */}
        <FormItem label="Map Link">
          {getFieldDecorator("details.mapLocation", {
            rules: [
              { required: true, message: "Please provide a map location" }
            ],
            initialValue: data.details.mapLocation
          })(<Input type="url" />)}
        </FormItem>

        <Row gutter={16}>
          <Col span={8}>
            {/* Price */}
            <FormItem label="Price">
              {getFieldDecorator("details.price", {
                rules: [{ required: true, message: "Please provide a price" }],
                initialValue: data.details.price
              })(<Input type="number" />)}
            </FormItem>
          </Col>

          <Col span={8}>
            {/* Pickup point */}
            <FormItem label="Pickup Point">
              {getFieldDecorator("details.pickupPoint", {
                // rules: [
                //   { required: true, message: "Please provide a pickup point" }
                // ],
                initialValue: data.details.pickupPoint
              })(<Input />)}
            </FormItem>
          </Col>

          <Col span={8}>
            {/* Pickup point */}
            <FormItem label="Duration">
              {getFieldDecorator("details.duration", {
                // rules: [
                //   { required: true, message: "Please provide a pickup point" }
                // ],
                initialValue: data.details.duration
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>

        {/* Price */}
        <FormItem label="Overview">
          {getFieldDecorator("details.overview", {
            rules: [{ required: true, message: "Please provide a overview" }],
            initialValue: data.details.overview
          })(<TextArea rows="4" />)}
        </FormItem>

        {/* Images */}
        <Row gutter={16} type="flex" align="middle">
          {imagesCounter.map((image, index) => (
            <Fragment>
              <Col span={22}>
                <FormItem key={index} label={`Image ${index + 1}`}>
                  {getFieldDecorator(`details.images[${index}]`, {
                    rules: [
                      // { required: true, message: "Please provide a pickup point" }
                    ],
                    initialValue: data.details.images[index]
                  })(<Input type="url" />)}
                </FormItem>
              </Col>
              <Col span={2}>
                {imagesCounter.length > 1 ? (
                  <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    disabled={imagesCounter.length === 1}
                    onClick={() => this.removeImage(index)}
                    style={{ cursor: "pointer" }}
                  />
                ) : null}
              </Col>
            </Fragment>
          ))}
        </Row>
        <FormItem>
          <Button type="dashed" onClick={this.addImage} style={{ width: 150 }}>
            <Icon type="plus" /> Add Image
          </Button>
        </FormItem>

        {/* Itinerary */}
        <Row gutter={16} type="flex" align="middle">
          {itinerary &&
            itinerary.map((item, itemIndex) => (
              <React.Fragment>
                <Col span={22}>
                  <FormItem label={`Day - ${itemIndex + 1}`}>
                    {getFieldDecorator(`itineraryFromUser[${itemIndex}]`, {
                      initialValue: item.content.join("\n\n")
                    })(<TextArea rows={8} />)}
                  </FormItem>
                </Col>
                <Col span={2}>
                  {itinerary.length > 1 ? (
                    <Icon
                      className="dynamic-delete-button"
                      type="minus-circle-o"
                      onClick={() => this.removeItineraryItem(itemIndex)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : null}
                </Col>
              </React.Fragment>
            ))}
        </Row>
        <FormItem>
          <Button
            type="dashed"
            onClick={this.addItineraryItem}
            style={{ width: 200 }}
          >
            <Icon type="plus" /> Add Day In Itinerary
          </Button>
        </FormItem>
        {/* Hide option */}
        <FormItem label="Hide from website">
          {getFieldDecorator("hide", {
            initialValue: !!data.hide,
            valuePropName: "checked"
            // rules: [{ required: true, message: "Please provide a name" }]
          })(<Switch />)}
        </FormItem>

        {/* Submit Button */}
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: 150 }}
          >
            Save
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(DetailsView);
