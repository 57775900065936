import React from "react";
import { css } from "emotion";
import { Button, Input } from "antd";
import { withRouter } from "react-router";

const logout = () => {
  try {
    localStorage.setItem("user", null);
    window.open("/login", "_self");
  } catch (err) {
    console.log(err);
  }
};

const Layout = ({
  children,
  history,
  hideAddButton,
  showSearchBar,
  onSearch,
  searchValue
}) => (
  <div>
    <div
      className={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 2px 0px;
        z-index: 11;
        padding: 10px 5vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div>
        <span
          onClick={() => history.push("/list")}
          className={css`
            cursor: pointer;
          `}
        >
          <img
            width="150"
            src="https://himalayanweekenders.com/static/media/solid_logo.4ade50aa.png"
            alt="logo"
          />
        </span>
      </div>
      <div>
        {showSearchBar ? (
          <Input
            className={css`
              width: 450px;
              margin-right: 40px;
            `}
            onChange={onSearch}
            value={searchValue}
            placeholder="Search products"
          />
        ) : null}
      </div>
      <div>
        <a href="/homepage-edit" style={{ marginRight: 25 }}>
          Edit homepage Carousel data
        </a>
        <a href="javascript:;" onClick={logout} style={{ marginRight: 15 }}>
          Logout
        </a>
        {hideAddButton ? null : (
          <Button
            style={{ width: 100 }}
            type="primary"
            onClick={() => history.push("/add")}
          >
            Add New
          </Button>
        )}
      </div>
    </div>
    <div style={{ marginTop: 100 }}>{children}</div>
  </div>
);

export default withRouter(Layout);
