import React, { Component } from "react";
import DetailsView from "./DetailsView";
import { getProductDetails, updateProductDetails } from "../../common/utils";
import { message } from "antd";
import Layout from "./Layout";

class UpdateView extends Component {
  state = {
    id: null,
    data: null
  };
  componentWillMount() {
    const { slug } = this.props.match.params;
    getProductDetails(slug).then(item => {
      this.setState({
        id: item.id,
        data: item.data
      });
    });
  }
  //   addToDB = data => {
  //     console.log(data);
  //     addToDB(data).then(id => {
  //       if (id === -1) {
  //         message.error("This item already exists");
  //       } else {
  //         message.success("Item added successfully");
  //         this.props.history.push("/list");
  //       }
  //     });
  //   };
  update = data => {
    updateProductDetails(data, this.state.id)
      .then(res => message.success("Item updated successfully"))
      .catch(err => message.error("Some error occurend. Please try again"));
  };
  render() {
    const { data } = this.state;
    return <DetailsView data={data} onSubmit={this.update} />;
  }
}

export default props => (
  <Layout>
    <UpdateView {...props} />
  </Layout>
);
