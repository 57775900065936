import React, { Component } from "react";
import DetailsView from "./DetailsView";
import { addToDB } from "../../common/utils";
import { message } from "antd";
import Layout from "./Layout";

class AddView extends Component {
  addToDB = data => {
    console.log(data);
    addToDB(data).then(id => {
      if (id === -1) {
        message.error("This item already exists");
      } else {
        message.success("Item added successfully");
        this.props.history.push("/list");
      }
    });
  };
  render() {
    return <DetailsView onSubmit={this.addToDB} />;
  }
}

export default props => (
  <Layout hideAddButton={true}>
    <AddView {...props} />
  </Layout>
);
